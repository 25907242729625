import http from '@/utils/httpRequest'

//产品内容一级看板
export function filmWatchCount(params){
    return http({
      url: http.adornUrl('/dataflow/olffilmwatch/oneFilmCount'),
      method:'get',
      params:params
    })
  }

//产品内容二级看板--专题
export function specialCount(params){
  return http({
    url: http.adornUrl('/dataflow/olffilmwatch/prdSpecialCount'),
    method:'get',
    params:params
  })
}

//产品内容二级看板--内容类型
export function contentCount(params){
  return http({
    url: http.adornUrl('/dataflow/olffilmwatch/prdContentCount'),
    method:'get',
    params:params
  })
}

//产品内容二级看板--产品
export function productCount(params){
  return http({
    url: http.adornUrl('/dataflow/olffilmwatch/productCount'),
    method:'get',
    params:params
  })
}

//产品内容二级看板--媒资
export function prdMediaCount(params){
  return http({
    url: http.adornUrl('/dataflow/olffilmwatch/prdMediaCount'),
    method:'get',
    params:params
  })
}
//获取内容产品状态时间
export function getPrdStateDate(){
  return http({
    url: http.adornUrl(`/dataflow/olffilmwatch/getPrdStateDate`),
    method: "post"
  })
}