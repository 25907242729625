<template>
  <div style="margin-top: 20px">
    <el-row v-if="showBool">
      <el-col :span="8">
        <el-tooltip class="item" effect="light" :content= "dataForm.coverUserDate" placement="top">
        <div id="coverOneId" @click="coverClick" ref="gradechartRef"></div>
        </el-tooltip>
      </el-col>
      <el-col :span="8">
        <el-tooltip class="item" effect="light" :content= "dateFormat(dataForm.onlineUserDate)" placement="top">
        <div id="onlineOneId" @click="onlineClick"></div>
        </el-tooltip>
      </el-col>
      <el-col :span="8">
        <div id="modeOneId" @click="modeClick" ref="canvas"></div>
      </el-col>
    </el-row>
    <el-row v-if="!showBool">
      <el-col :span="8">
        <div id="onlineOneId" class="online" @click="onlineClick"></div>
      </el-col>
      <el-col :span="8">
        <div id="modeOneId" @click="modeClick" ref="canvas"></div>
      </el-col>
      <el-col :span="8">
        <div id="mall1">
          <div class="wgjl">
            广电商城
          </div>
          <div class="gdsc">
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px" v-if="showBool">
      <el-col :span="12">
        <el-tooltip class="item" effect="light" :content= "dataForm.onrealUserDate" placement="top">
        <div id="watchOneId" @click="watchClick" ref="canvas"></div>
        </el-tooltip>
      </el-col>
      <el-col :span="12">
        <el-tooltip class="item" effect="light" :content= "dataForm.prdStateDate" placement="top">
        <div id="cpsOneId" @click="cpsClick" ref="canvas"></div>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px" v-if="showBool">
      <el-col :span="8">
        <el-tooltip class="item" effect="light" :content= "dataForm.majorWarningDate" placement="top">
        <div id="warnId" @click="warnClick" ref="canvas"></div>
        </el-tooltip>
      </el-col>
      <el-col :span="8">
        <div id="gridId" @click="gridClick">
          <div class="wgjl">网格经理(管理)</div>
          <el-container>
            <el-main>
              <!-- <template>   :row-class-name="tableRowClassName"  -->
              <el-table :data="dataList" v-loading="dataListLoading" style="width: 100%;margin-top:40px;" height="250"
                v-loadmore="loadMore">
                <el-table-column prop="dayDate" header-align="center" align="center" label="日期" :formatter="dateFormat_ymd">
                </el-table-column>
                <el-table-column prop="dayName" header-align="center" align="center" label="标题">
                </el-table-column>
                <el-table-column prop="dayState" header-align="center" align="center" label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.dayState == '01'"><span style="color: red;font-size: 14px;">完成</span></span>
                    <span v-if="scope.row.dayState == '02' && scope.row.percentage === 100"><span
                        style="color:red;font-size: 14px;">待处理</span></span>
                    <span v-else><span style="color: #309DF0;font-size: 14px;">待处理</span></span>
                    <span v-if="scope.row.dayState == '03'"><span
                        style="color: #8FC975;font-size: 14px;">已处理</span></span>
                  </template>
                </el-table-column>
              </el-table>
              <!-- </template> -->
            </el-main>
          </el-container>
        </div>
      </el-col>
      <el-col :span="8">
        <div id="mall">
          <div class="wgjl">
            广电商城
          </div>
          <div class="gdsc">
            <!--<img src="~@/assets/img/gdsc.png">-->
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px" v-if="!showBool">
      <el-col :span="24">
        <div id="grid" @click="gridClick">
          <div class="wgjl">网格经理(管理)</div>
          <el-container>
            <el-main>
              <!-- <template>   :row-class-name="tableRowClassName"  -->
              <el-table :data="dataList" v-loading="dataListLoading" style="width: 100%;margin-top:40px;" height="400"
                v-loadmore="loadMore">
                <el-table-column prop="dayDate" header-align="center" align="center" label="日期" :formatter="dateFormat_ymd">
                </el-table-column>
                <el-table-column prop="dayName" header-align="center" align="center" label="标题">
                </el-table-column>
                <el-table-column prop="dayState" header-align="center" align="center" label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.dayState == '01'"><span style="color: red;font-size: 14px;">完成</span></span>
                    <span v-if="scope.row.dayState == '02' && scope.row.percentage === 100"><span
                        style="color:red;font-size: 14px;">待处理</span></span>
                    <span v-else><span style="color: #309DF0;font-size: 14px;">待处理</span></span>
                    <span v-if="scope.row.dayState == '03'"><span
                        style="color: #8FC975;font-size: 14px;">已处理</span></span>
                  </template>
                </el-table-column>
              </el-table>
              <!-- </template> -->
            </el-main>
          </el-container>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getListByUser } from "../../api/hbi/report";
import { coverCount, onlineCount, userState } from "../../api/kanban/secondLevel";
import { realTimeCount } from "../../api/kanban/realTime";
import { filmWatchCount,getPrdStateDate } from "../../api/kanban/filmWatch";
import { getGrid, getNewList } from "../../api/kanban/grid";
import { oneMajorWaring,getMajorWarningDate } from "../../api/kanban/majorWarning";
import elementResizeDetectorMaker from "element-resize-detector";
import {getCoverUserDate,getOnlineUserDate,getOnrealUserDate} from "@/api/dataflow/olfcoveruser";

export default {
  data() {
    return {
      timedTasksCollector: null,
      loading: false,
      dataListLoading: false,
      jsonData: {},
      id: '',
      userCount: '',
      models: {},
      name: '',
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      busy: true,
      scrollTop: 0,
      date:'',
      dataForm:{
        coverUserDate:'',
        onlineUserDate:'',
        onrealUserDate:'',
        majorWarningDate:'',
        prdStateDate:'',
      }
    };
  },
  components: {

  },
  activated() {

  },
  computed: {
    levelType: {
      get() {
        return this.$store.state.user.levelType;
      }
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      }
    },
    regionId: {
      get() {
        return this.$store.state.user.regionId;
      }
    },
    gridId: {
      get() {
        return this.$store.state.user.gridId;
      }
    },
    regionCode: {
      get() {
        return this.$store.state.user.regionCode;
      }
    },
    gridCode: {
      get() {
        return this.$store.state.user.gridCode;
      }
    },
    showBool:function(){
      if(this.gridId == '' || this.gridId == null || this.gridId == undefined){
        return true;
      }else{
        return false;
      }
    }
  },
  mounted() {
    this.timedTasksCollector = setInterval(() => {
      this.watchChart();
      console.log(window.location.pathname);
    }, 30000);
    this.initCanvas();
    this.getCoverUserDate();
    this.getOnlineUserDate();
    this.getOnrealUserDate();
    this.getPrdStateDate()
  },
  beforeRouteLeave(to, from, next){
    next();
    if (this.timedTasksCollector) {
      clearInterval(this.timedTasksCollector);
      this.timedTasksCollector = null;
    }
  },
  methods: {
    initCanvas() {
      if(this.showBool){
      this.getCoverCount();
      this.watchChart();
      this.getUserState();/// 用户状态
      this.getOnlineCount();
      this.oneFilmWatchCount();
      this.getGrig();
      this.majorWarningCount();
      this.getMajorWarningDate();
      }else{
        this.getUserState();/// 用户状态
        this.getOnlineCount();
        this.getGrig();
      }
      this.getNowDate()
    },
    //指定行颜色
    tableRowClassName: function ({ row, rowIndex }) {
      // console.log(row, 'row');
      if (row.percentage === 100) {
        return 'warm-row';
      }
    },
    // 获取数据列表
    getDataList() {
      // this.dataListLoading = true

      // const params_ = {
      //   'page': 1,
      //   'limit': 6,
      // }
      // todoList(params_).then(({ data }) => {
      //   if (data && data.code === 0) {
      //     this.dataList = data.data.list
      //     //this.totalCount = data.data.totalCount
      //   } else {
      //     this.dataList = []
      //     //this.totalCount = 0
      //   }

      // }).finally(() => {
      //   this.dataListLoading = false
      // })
    },
    // init() {
    //   getListByUser().then(({ data }) => {
    //     if (data && data.data && data.code === 0) {
    //       this.name = data.data.name
    //       // 取得表单
    //       const formModel = data.data.templateData
    //       const forms = formModel ? JSON.parse(formModel) : null
    //       this.jsonData = forms
    //     } else {
    //       this.id = ''
    //     }

    //   })
    // },

    // 覆盖用户
    getCoverCount() {
      const _this = this;
      const params_ = {
        'areaCode': this.gridId !== undefined ? this.gridId : this.regionId,
      }
      coverCount(params_).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          const newdata = data.data.list;
          const sum = data.data.sum;
          const newList = [];
          newdata.forEach(element => {
            const newobj = {};
            newobj.value = element.coverSum/10000;
            newobj.name = _this.setType(element.channelType);
            newList.push(newobj)
          });
          this.coverChart(newList,sum)
        }
      })
    },
    setType(e) {
      switch (e) {
        case '01':
          return '单向网'
        case '02':
          return '双向网'
        case '03':
          return '农网'
      }
    },

    coverChart(e,e2) {
      var myChart = this.$echarts.init(document.getElementById('coverOneId'));
      var option;
      option = {
        title: [{
          text: '覆盖用户',
          left: 'left',
          // padding: 24,
          textStyle: {
            // width: 97,
            // height: 24,
            fontFamily: 'Microsoft YaHei',
            fontSize: 24,
            fontWeight: 'bold',
          }
        },
          {
            text: '总数:'+ e2+ '(万)',
            left: 'right',
            textStyle:{
              fontSize:14,
              fontWeight: 'normal'

            }
          }],
        tooltip: {
          formatter: (params) => {
            return params.name + '<br>' +
                    params.marker + ' ' + params.seriesName + ': ' + params.data.value + ' 万' + '<br>' ;
          },
        },
        color: ["#309DF0", "#8FC975", "#FCAE04"],
        label: {
          alignTo: 'edge',
          formatter: '{c} 万',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
        },
        labelLine: {
          length: 15,
          length2: 0,
          maxSurfaceAngle: 80
        },
        legend: {
          orient: 'horizontal',
          y: 'bottom',
          x: 'center',
          itemGap: 40,
          selectedMode: false
        },
        series: [
          {
            name: '覆盖用户',
            type: 'pie',
            radius: ['59', '87'],
            data: e,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },

    //在线用户
    getOnlineCount() {
      const _this = this;
      const params_ = {
        'areaCode': this.gridId !== undefined ? this.gridId : this.regionId,
      }
      onlineCount(params_).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          // _this.userCount = data.data.onlineSum;
          const newdata = data.data.onlineSum;
          const newList = [];
          const newobj = {};
          newobj.value = newdata;
          newList.push(newobj);
          this.onlineChart(newList)
        }
      })
    },
    // 在线用户数
    onlineChart(e) {
      var onlineDom = document.getElementById('onlineOneId');
      var myChart = this.$echarts.init(onlineDom);
      var option;
      option = {
        title: {
          text: '在线用户数',
          left: 'left',
          // padding: 24,
          textStyle: {
            // width: 97,
            // height: 24,
            fontFamily: 'Microsoft YaHei',
            fontSize: 24,
            fontWeight: 'bold',
          }
        },
        series: [
          {
            type: 'gauge',
            center:['50%','55%'],
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.3, '#67e0e3'],
                  [0.7, '#37a2da'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              show: false,
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              show: false,
              color: 'auto',
              distance: 40,
              fontSize: 20
            },
            detail: {
              formatter: function (params) {
                if (Object.is(params, NaN)) {
                  return "0";
                } else {
                  return params;
                }

              },
              color: 'auto'
            },
            data: e
          }
        ]
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },

    // 实时观看用户
    watchChart() {
      var chartDom = document.getElementById('watchOneId');
      var myChart = this.$echarts.init(chartDom);
      var option;
      let dataList = [];
      let newListX = [];
      let newListY = [];
      let sum = 0;
      const params_ = {

        'areaCode':this.gridCode !== undefined ? this.gridCode : this.regionCode
      }

      realTimeCount(params_).then(({ data }) => {
        console.log(111111)
        if (data && data.data && data.code === 0) {
          sum = data.data.sum;
          console.log(sum,22222)
          data.data.list.forEach(element => {
            dataList = data.data.list
            newListX.push(parseInt(element.realSum))
            newListY.push(element.regionName)
          });
          option = {
            title: [{
              text: '实时观看用户',
              // left: 'left',
              // left: 25,
              // top: 25,
              textStyle: {
                //文字颜色
                color: '#464646',
                //字体风格,'normal','italic','oblique'
                fontStyle: 'normal',
                //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
                fontWeight: 'Bold',
                //字体系列
                fontFamily: 'MicrosoftYaHei-Bold',
                //字体大小
                fontSize: 24,
              },
            },
            {
              text: '总数:'+sum+'(人)',
              left: 'right',
              textStyle:{
                fontSize:14,
                fontWeight: 'normal'
              }
            }],
            tooltip: {
              trigger: 'axis',
              axisPointer : {            // 坐标轴指示器，坐标轴触发有效
                type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: (params) => {
                return params[0].name + '<br>' +params[0].marker + ' ' +  params[0].data + ' 人' + '<br>'
              },
            },
            grid: {
              // left: '25',
              //right: '15%',
              bottom: '0',
              top: "100",
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: newListY,
              inverse: true,
              axisLabel: {
                interval: 0,
                rotate: 40,
                formatter: function(params) {
                  var newParamsName = ''// 最终拼接成的字符串
                  var paramsNameNumber = params.length// 实际标签的个数
                  var provideNumber = 10// 每行能显示的字的个数
                  // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                  if (paramsNameNumber > provideNumber) {
                    // ********重点在这里********
                    newParamsName = params.substring(0, 10) + '..'// 最终拼成的字符串
                  } else { // 将旧标签的值赋给新标签
                    newParamsName = params
                  }
                  // 将最终的字符串返回
                  return newParamsName
                }
              }
            },
            yAxis: [{
              //左侧y轴
            },
              {
                //右侧y轴
                nameLocation:'end',
                // name: '单位：人'
              }],
            series: [
              {
                realtimeSort: true,
                // name: '实时观看人员',
                type: 'bar',
                data: newListX,
                label: {
                  show: true,
                  position: 'top',
                  valueAnimation: true
                },
                barWidth: 10,
                itemStyle: {
                  //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                  normal: {
                    //柱形图圆角，初始化效果
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: "#309DF0" // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: "#59E4F9" // 100% 处的颜色
                    }], false)
                  }
                }
              }
            ],
            legend: {
              show: true
            },
            animationDuration: 0,
            animationDurationUpdate: 3000,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear'
          };

          //动态运行函数
          // eslint-disable-next-line no-inner-declarations
            realTimeCount(params_).then(({ data }) => {
              if (data && data.data && data.code === 0) {
                // sum = data.data.sum;
                // data.data.list.forEach(element => {
                //   dataList = data.data.list
                //   newListX.push(parseInt(element.realSum))
                //   newListY.push(element.regionName)
                // });
                myChart.setOption({
                  series: [
                    {
                      type: 'bar',
                      data: newListX
                    }
                  ]
                });
                window.addEventListener("resize", () => {
                  myChart.resize();
                })
              }
            })

          option && myChart.setOption(option);
          setTimeout(() => {
            console.log(new Date)
            realTimeCount(params_)
          }, 120000)
        }

      })
      setTimeout(() => {
        realTimeCount(params_)
      }, 10000)

    },
    created(){
      this.watchChart()
    },
    // option && myChart.setOption(option);



    //用户状态
    getUserState() {
      const _this = this;
      const params_ = {
        'areaCode': this.gridId !== undefined ? this.gridId : this.regionId,
      }
      userState(params_).then(({ data }) => {
        if (data && data.data && data.code === 0) {

          const newdata = data.data.list;
          const firstDayOfMonth = data.data.firstDayOfMonth;
          const LastDayOfMonth = data.data.LastDayOfMonth;
          const newList = [];
          const newList1 = [];
          newdata.forEach(element => {
            const newobj = {};
            //newobj.value = element.stateSum;
            //newobj.name = _this.setuserStateType(element.stateType);
            newList.push(element.stateSum)
            newList1.push(_this.setuserStateType(element.stateType))
          });
           let List = newList.reverse();
           let List1 = newList1.reverse();
          this.modeChart(List, List1,firstDayOfMonth,LastDayOfMonth)
        }
      })
    },
    setuserStateType(e) {
      switch (e) {
        case '01':
          return '新增'
        case '02':
          return '续费'
        case '03':
          return '报停'
        case '04':
          return '欠费'
        case '05':
          return '转套餐'
        case '06':
          return '流失'
      }
    },
    // 用户状态
    modeChart(e1, e2,e3,e4) {

      var modeDom = document.getElementById('modeOneId');
      var myChart = this.$echarts.init(modeDom);
      var option;
      option = {
        title: [{
          text: '用户状态',
          left: 'left',
          //padding: 24,
          textStyle: {
            // width: 97,
            // height: 24,
            fontFamily: 'Microsoft YaHei',
            fontSize: 24,
            fontWeight: 'bold',
          }
        },
          {
            text: e3.substring(0,10).replace(/-/g, '/') + '-' + e4.substring(0,10).replace(/-/g, '/'),
            left: 'right',
            textStyle:{
              fontSize:14,
              fontWeight: 'normal'
            }
          }],
        textStyle: {
          fontFamily: 'MicrosoftYaHei-Bold',
        },
        tooltip: {
          textStyle: {
            color: 'black',//字体颜色
          },
        },
        yAxis: [{
          data: e2,
          axisTick: {       //x轴刻度线
            show: false
          },
          splitLine: {     //网格线
            show: false
          },
          axisLine: {    //坐标轴线
            show: false
          },
          axisLabel: {
            textStyle: {
              color: function (params) {
                if (params === "新增") {
                  return "#8FC975"
                } else if (params === "续费") {
                  return "#309DF0"
                } else if (params === "报停") {
                  return "#FCAE04"
                } else if (params === "欠费") {
                  return "#47D0E7"
                } else if (params === "转套餐") {
                  return "#B46CE7"
                } else if (params === "流失") {
                  return "#EE6666"
                }
              }
            }
          }

        },
        {
          //右侧y轴
          axisLine: {    //坐标轴线
            show: false
          },
          nameLocation:'end',
          //name: '单位：个'
        }],
        xAxis: {
          show: false,
          //max: 100,
          min: 0,
        },
        grid: {
          left: '25',
          //right: '15%',
          bottom: '0',
          //top:"100",
          containLabel: true
        },
        series: [{
          type: 'bar',
          data: e1,
          barWidth: 15,//柱图宽度
          //标签
          label: {
            normal: {
              show: true,
              position: 'right',
              formatter: '{c}',//模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
            },
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 10, 10], // 统一设置四个角的圆角大小
              //这里是重点
              color: function (params) {
                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                var colorList = ["#8FC975", "#309DF0", "#FCAE04", "#47D0E7", "#B46CE7", "#EE6666"];
                return colorList[params.dataIndex]
              }
            }
          }
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },
    //产品内容状态
    oneFilmWatchCount() {
      const time = this.dateFormat(new Date());
      const _this = this;
      filmWatchCount(
        {
          newTime: time,
          'areaCode': this.gridId !== undefined ? this.gridId : this.regionId
        }
      ).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          var newdata = data.data;
          const newList = [];
          const newList1 = [];
          newdata.forEach(element => {
            newList.push(element.filmName)
            newList1.push(element.playSum)
          });
          _this.cpsChart(newList,newList1)
        }
      })
    },
    // 内容产品状态
    cpsChart(e1,e2) {
      var app = {};
      var cpsDom = document.getElementById('cpsOneId');
      var myChart = this.$echarts.init(cpsDom);
      var option;
      option = {
        title: [{
          text: '内容产品状态TOP10',
          left: 'left',
          // padding: 24,
          textStyle: {
            // width: 97,
            // height: 24,
            fontFamily: 'Microsoft YaHei',
            fontSize: 24,
            fontWeight: 'bold',
          }
        },
        {
          text:'统计时间：'+this.date.substring(0,10).replace(/-/g, '/'),
          left: 'right',
          textStyle: {
            fontSize: 14,
            fontWeight: 'normal'
          }
        }
      ],
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            align: 'left'
          },
        },
        xAxis: {
          data:e1,
          type: 'category' ,
          axisLabel: {
            interval: 0,
            rotate: 40,
            formatter: function(params) {
              var newParamsName = ''// 最终拼接成的字符串
              var paramsNameNumber = params.length// 实际标签的个数
              var provideNumber = 10// 每行能显示的字的个数
              // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
              if (paramsNameNumber > provideNumber) {
                // ********重点在这里********
                newParamsName = params.substring(0, 6) + '..'// 最终拼成的字符串
              } else { // 将旧标签的值赋给新标签
                newParamsName = params
              }
              // 将最终的字符串返回
              return newParamsName
            }
          }
        },
        yAxis: { },
        grid: {
          left: '25',
          //right: '15%',
          bottom: '10',
          top: "100",
          containLabel: true
        },
        series: [{
          type: 'bar',
          data:e2,
          color: '#59E4F9',
          barWidth: 15,
          label: {
            normal: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'black'
              }
            }
          },
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [10, 10, 10, 10]
            }
          }
        }]
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },

    //
    gridChart1() {
      var gridDom = document.getElementById('gridId');
      var myChart = this.$echarts.init(gridDom);
      var option;
      option = {
        legend: {},
        tooltip: {},
        dataset: {
          source: [
            ['product', '待处理', '异常信息',],
            ['1', 43.3, 85.8,],
            ['2', 83.1, 73.4,],
            ['3', 86.4, 65.2,],
            ['4', 45, 30,],
          ]
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [{ type: 'bar' }, { type: 'bar' },]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },

    //网格经理
    getGrig1() {
      const _this = this;
      const params_ = {
        // 'areaCode': this.$cookie.get("grid") !== 'undefined' ? this.$cookie.get("grid") : this.$cookie.get("regionId"),
        'areaCode': this.gridId !== undefined ? this.gridId : this.regionId,
        // 'isGrid': this.$cookie.get("grid") !== 'undefined' ? '1' : '0'
        'isGrid': this.gridId !== undefined ? 1 : 0,
      }
      getGrid(params_).then(({ data }) => {
        // console.log("data>="+JSON.stringify(data))
        if (data && data.data && data.code === 0) {
          const newdata = data.data;
          const newListX = [];
          const newListY = [];
          const newListV = [];
          newdata.forEach(element => {
            newListX.push(element.dayName)
            newListY.push(element.percentage)
            newListV.push(element.perCentum)
          });
          this.gridChart(newListX, newListY, newListV)
        }
      })
    },

    getGrig() {
      const _this = this;
      const params_ = {
        'areaCode': _this.gridId !== undefined ? _this.gridId : _this.regionId,
        'isGrid': _this.gridId !== undefined ? 1 : 0,
        'page': _this.pageIndex,
        'limit': _this.pageSize,
      }
      getGrid(params_).then(({ data }) => {
        if (data && data.code === 0) {
          _this.dataList = data.data.list
          _this.totalCount = data.data.totalCount
        } else {
          _this.dataList = []
          _this.totalCount = 0
          _this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        _this.dataListLoading = false;
      })
    },

        // 表格滚动加载事件
        loadMore(scrollTop) {
      if (this.scrollTop <= scrollTop) {
        this.scrollTop = scrollTop;
        if (this.busy) {
          this.busy = false
          this.pageIndex++
          if (this.pageIndex > this.totalCount || this.totalCount == 0) {
            this.$message.warning('已经到底了~')
            return
          }
          setTimeout(() => {
            const params_ = {
              'areaCode': this.gridId !== undefined ? this.gridId : this.regionId,
              'isGrid': this.gridId !== undefined ? 1 : 0,
              'page': this.pageIndex,
              'limit': this.pageSize,
            }
            getGrid(params_).then(({ data }) => {
              if (data && data.code === 0) {
                var list = this.dataList.concat(data.data.list)
                this.dataList = list
              } else {
                this.dataList = []
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              console.error(err)
            }).finally(() => {
              this.dataListLoading = false;
            })
            this.busy = true
          }, 100)
        }
      }

    },


    gridChart(dataX, dataY, dataV) {
      var chartDom = document.getElementById('gridId');
      var myChart = this.$echarts.init(chartDom);
      const option = {
        title: {
          text: '网格经理',
          left: 'center'
        },
        tooltip: {
          show: false,
        },
        grid: {
          left: '3%',
          right: '6%',
          bottom: '5%',
          top: '15%',
          //图形距离边框的距离，建议百分比，如果数值的话，分辨率改变，图表会变得很丑
          containLabel: true,//grid 区域是否包含坐标轴的刻度标签,默认不包含
        },
        xAxis: {
          boundaryGap: true,
          data: dataX,
          axisTick: {
            alignWithLabel: true,
            show: false,
          },
          axisLabel: {
            interval: 0,
            margin: 14,
            fontSize: 14,
            fontFamily: 'ShiShangZhongHeiJianTi',
            color: 'rgba(255,255,255,0.8)',
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.1)',
            },
            symbol: ['none', 'arrow'], // 坐标直角
          },
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              margin: 14,
              color: '#999999',
            },
            axisLine: {
              lineStyle: {
                color: '#FFFFFF',
                width: 1,
              },
            },
            splitLine: {
              show: false,
            },
            splitArea: {
              interval: 10,
            },
          },
        ],
        series: [
          {
            name: '',
            type: 'pictorialBar', // 设置类型为象形柱状图
            symbol: 'Rect', // 图形类型矩形
            barWidth: '11%', // 柱图宽度
            barMaxWidth: '20%', // 最大宽度
            symbolMargin: '3', // 图形垂直间隔
            slient: true,
            itemStyle: {
              normal: {},
            },
            color: '#41A2F6',
            z: 1,
            symbolRepeat: true, // 图形是否重复
            symbolSize: [25, 8], // 图形元素的尺寸
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: '{c}',
                textStyle: {
                  color: '#fff',
                  fontSize: 14,
                },
              },
            },
            data: dataY, // Y轴数据
            animationEasing: 'elasticOut', // 动画效果
          },
          {
            name: '外框',
            type: 'bar',
            barGap: '-120%', // 设置外框粗细
            slient: true,
            data: dataV,
            barWidth: 25,
            color: 'red',
            itemStyle: {
              normal: {
                // color: 'transparent', // 填充色
                color: 'rgba(76,105,125,0.1)', // 填充色
                barBorderWidth: 1, // 边框宽度
                label: {
                  // 标签显示位置
                  show: false,
                  position: 'top', // insideTop 或者横向的 insideLeft
                },
              },
            },
            z: 0,
          },
        ],
      };
      myChart.setOption(option, true);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },
    //重大警示
    majorWarningCount() {
      const _this = this;
      oneMajorWaring(
        {
          'areaCode': this.gridId !== undefined ? this.gridId : this.regionId
        }
      ).then(({ data }) => {
        if (data && data.data && data.code === 0) {
          const newdata = data.data.list;
          const sum = data.data.sum;
          var newList = []
          newdata.forEach(element => {
            newList = _this.getObjectValues(element)
          });
          _this.warnChart(newList,sum)
        }
      })
    },
    //重大警示
    warnChart(e,e2) {
      var app = {};
      var warnDom = document.getElementById('warnId');
      var myChart = this.$echarts.init(warnDom);
      var option;

      option = {
        title: [{
          text: '重大警示',
          left: 'left',
          // padding: 24,
          textStyle: {
            // width: 160,
            // height: 24,
            fontFamily: 'Microsoft YaHei',
            fontSize: 24,
            fontWeight: 'bold',
          }
        },
          {
            text: '总数:'+ e2+'('+'个'+')',
            left: 'right',
            textStyle: {
              fontSize: 14,
              fontWeight: 'normal'
            }
          }
        ],
        legend: {},
        tooltip: {},
        xAxis: {
          type: 'category',
          data: ['续费异常', '流失异常', '套餐异常', '报停异常', '新增异常'],
          axisLabel: {
                interval:0,//代表显示所有x轴标签显示
            }
        },
        yAxis: {},
        grid: {
          left: '25',
          //right: '15%',
          bottom: '0',
          top: "100",
          containLabel: true
        },
        series: [{
          //name: '重大警示',
          type: 'bar',
          color: '#FFCE34',
          data: e,
          barWidth: 15,//柱图宽度
          label: {
            normal: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'black'
              }
            }
          }
        }]
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
    },

    coverClick() {
      this.$router.push({ name: 'kanban_cover', params: { regionId: this.$store.state.user.regionId } });
    },
    onlineClick() {
      this.$router.push({ name: 'kanban_online', params: { regionId: this.$store.state.user.regionId } })
    },
    watchClick() {
      this.$router.push({ name: 'kanban_readTime', params: { regionId: this.$store.state.user.regionId } })
    },
    modeClick() {
      this.$router.push({ name: 'kanban_userState', params: { regionId: this.$store.state.user.regionId } });
    },
    cpsClick() {
      this.$router.push({ name: 'kanban_film', params: { regionId: this.$store.state.user.regionId } });
    },
    gridClick() {
      this.$router.push({ name: 'kanban_grid', params: { regionId: this.$store.state.user.regionId } })
    },
    warnClick() {
      this.$router.push({ name: 'kanban_major', params: { regionId: this.$store.state.user.regionId } })
    },


    //时间格式化函数，此处仅针对yyyy-MM-dd的格式进行格式化
    dateFormat(time) {
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // 拼接
      return year + '-' + month + '-' + day
    },
    getObjectValues(object) {
      var values = [];
      for (var property in object)
        values.push(object[property]);
      return values;
    },
    /**
     * 查询当天日期
     */
     getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      this.date = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
    //获取覆盖用户时间
    getCoverUserDate(){
      getCoverUserDate().then(({data}) => {
        this.dataForm.coverUserDate = data.data
      })
    },
    //获取在线用户时间
    getOnlineUserDate(){
      getOnlineUserDate().then(({data}) => {
        this.dataForm.onlineUserDate = data.data
      })
    },
    //获取实时用户时间
    getOnrealUserDate(){
      getOnrealUserDate().then(({data}) => {
        this.dataForm.onrealUserDate = data.data
      })
    },
    //获取重大警示时间
    getMajorWarningDate(){
      getMajorWarningDate().then(({data}) => {
        this.dataForm.majorWarningDate = data.data
      })
    },
    //获取内容产品状态时间
    getPrdStateDate(){
      getPrdStateDate().then(({data}) => {
        this.dataForm.prdStateDate = data.data
      })
    },
  },
};
</script>
<style lang="scss">
.warm-row {
  background-color: red !important;
}

.el-header {
  background-color: #FFFFFF;
  color: #464646;
  text-align: left;
  margin-left: 25px;
  margin-top: 25px;
  line-height: 60px;
  font-size: 24px;
  font-weight: bold;
  font-family: 'MicrosoftYaHei-Bold'
}

.el-main {
  margin: 0 25px 0 25px
}

.num {
  font-size: 32px;
}

.title {
  font-size: 18px;
  margin-block-start: 5px;
  margin-block-end: 13px;

}

.more-info {
  float: right;
  margin-right: 20px;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}


.grid-container {
  display: grid;

  grid-template-columns: 30% 20% 20% 20%;
  grid-template-rows: 50px 125px;

  /* 水平平均 */
  justify-content: space-evenly;
  /* 垂直平均 */
  justify-content: space-evenly;
  align-content: space-evenly;

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  div {
    border: 1px #000 solid;
  }


  .project-info {
    grid-column: 2 / 5;
  }


  div:nth-of-type(1) {
    border: none;
  }

  div:nth-of-type(2) {
    border: none;
  }

  div:nth-of-type(n+3) {
    border-radius: 10px;
    text-align: center;
    line-height: 120px;
    border: 1px solid #E0E5E8;
    box-shadow: 0px 2px 5px 0px rgba(180, 180, 180, 0.35);

  }

  .undo {
    background: url(~@/assets/img/prj/prj-undo.png) no-repeat;
    background-position: 100% 0%;
    background-size: 70px;

  }

  .doing {
    background: url(~@/assets/img/prj/prj-doing.png) no-repeat;
    background-position: 100% 0%;
    background-size: 70px;

  }

  .done {
    background: url(~@/assets/img/prj/prj-done.png) no-repeat;
    background-position: 100% 0%;
    background-size: 70px;

  }


}


.grid-content {
  display: grid;
  grid-template-columns: 23% 20% 20% 27%;
  grid-template-rows: 50px 125px 50px 300px;
  margin-top: 25px;
  //grid-gap:15px 25px;

  /* 水平平均 */
  justify-content: space-evenly;
  /* 垂直平均 */
  justify-content: space-evenly;
  align-content: space-evenly;

  //grid-auto-flow: row dense;

  div {
    //background-color: red;
    border: 1px #000 solid;
    border-radius: 8px;
    // height: 100%;
    //overflow: auto;

  }

  .item-cell3 {
    grid-column: 1/4;
  }

  .item-notice {
    grid-row: 2/5;
    grid-column: 4/5;

    ::v-deep.el-divider--horizontal {
      margin: 5px 0 !important;
    }

    ul li::marker {
      color: #1079F4;
      font-size: 30px;
      border-radius: 50%;

    }

    .notice-title {
      font-size: 16px;
      cursor: pointer;
    }

  }

  .item-wait {
    grid-column: 1/4;
    margin-top: 25px;
  }

  div:nth-of-type(1) {
    border: none;
  }

  div:nth-of-type(2) {
    border: none;
  }

  div:nth-of-type(n+3) {
    border: 1px solid #E0E5E8;
    box-shadow: 0px 2px 5px 0px rgba(180, 180, 180, 0.35);
  }

  // 待办标题
  div:nth-of-type(7) {
    border: none;
    box-shadow: none;
    grid-column: 1/4;
    margin-top: 20px;
  }
}

.f-left {
  display: inline-block;
  margin-top: 10px;
  margin-left: 5px;

  .title {
    text-align: center;
  }
}

.f-right {
  display: inline-block;
  //vertical-align: middle;
  float: right;
  margin-right: 5px;
  margin-top: 30px;

  img {
    vertical-align: middle;

    width: 65px;
    height: 64px;
  }

}

.d-top {
  width: 100%;
  height: 220px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.d-top div {
  width: 24%;
}

//新版样式
.rect-config {
  /*width: 1705px;*/
  height: 980px;
  background: #FFFFFF;
}

//第一行
.firstLine {
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

#coverOneId {
  /*width: 30%;*/
  height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#onlineOneId {
  margin-left: 10px;
  /*width: 30%;*/
  height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#modeOneId {
  margin-left: 10px;
  margin-right: 5px;
  /*width: 30%;*/
  height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#mall1 {
  margin-left: 5px;
  margin-right: 5px;
  height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

//第二行
.secendLine {
  display: flex;
  justify-content: space-around;
}

#watchOneId {
  height: 293px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#cpsOneId {
  margin-left: 10px;
  margin-right: 5px;
  height: 293px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

//第三行
.thirdLine {
  display: flex;
  justify-content: space-around;
}

#warnId {
  height: 314px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#gridId {
  margin-left: 10px;
  height: 314px;
  background: #FAFAFA;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#grid {
  margin-left: 10px;
  margin-right: 5px;
  height: 500px;
  background: #FAFAFA;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

#mall {
  margin-left: 10px;
  margin-right: 5px;
  height: 314px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
}

.wgjl {
  /*padding-top: 20px;*/
  margin-left: 5px;
  width: 183px;
  height: 25px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #464646;
  z-index: 9999;
}

.gdsc {
  width: 331px;
  height: 238px;
  margin: auto;
  background-image: url(~@/assets/img/gdsc.png);
}

.zxyh {
  margin-left: 24px;
  width: 539px;
  height: 293px;
  background-color: #F3FEFD;
  box-shadow: 0px 0px 8px 0px #CCCCCC;
  /*border: 1px solid red;*/
  display: flex;
}

.zxyh-a {
  width: 80%;
  height: 80%;
  /*border: 1px solid rebeccapurple;*/
  margin: auto;
  background: url(~@/assets/img/zxyh.png) no-repeat center;
  background-size: contain;
  position: relative;
  margin-right: 150px;
  margin-top: 50px
}

.zxyh-p {
  font-size: 46px;
  color: #1079F4;
  position: absolute;
  left: 45%;
  top: 31%;
  transform: translate(-50%, -50%);
}
</style>
