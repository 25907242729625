import http from '@/utils/httpRequest'

// 一级覆盖用户
export function coverCount(params) {
  return http({
    url: http.adornUrl('/dataflow/olfcoveruser/coverCount'),
    method: 'get',
    params: params
  })
}

// 二级覆盖用户
export function twoCoverCount(params) {
  return http({
    url: http.adornUrl('/dataflow/olfcoveruser/twoCoverCount'),
    method: 'get',
    params: params
  })
}

//一级在线用户
export function onlineCount(params) {
  return http({
    url: http.adornUrl('/dataflow/olfonlineuser/onlineCount'),
    method: 'get',
    params: params
  })
}

//二级在线用户
export function twoOnlineCount(params) {
  return http({
    url: http.adornUrl('/dataflow/olfonlineuser/twoOnlineCount'),
    method: 'get',
    params: params
  })
}

//一级用户状态
export function userState(params) {
  return http({
    url: http.adornUrl('/dataflow/olfuserstate/userStateCount'),
    method: 'get',
    params: params
  })
}

// 二级用户状态
export function twoUserStateCount(params) {
  return http({
    url: http.adornUrl('/dataflow/olfuserstate/twoUserStateCount'),
    method: 'get',
    params: params
  })
}


