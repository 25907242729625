import http from '@/utils/httpRequest'

//获取数据
export function getList(params){
    return http({
      url: http.adornUrl('/dataflow/olfonrealuser/list'),
      method: 'get',
      params: params
    })
  }

// 一级看板-实时观看用户
export function realTimeCount(params) {
  return http({
    url: http.adornUrl('/dataflow/olfonrealuser/queryRealSum'),
    method: 'get',
    params: params
  })
}

// 导出excel
export function exportExcel(params) {
  return http({
    url: http.adornUrl('/dataflow/olfonrealuser/exportExcel'),
    method: 'get',
    params: params
  })
}

//导出excel
export function exportToExcel(config){
  
  var iform = document.createElement("iframe");
  iform.setAttribute("name", "downloadIframe");
  iform.setAttribute("style", "display:none");
  document.body.appendChild(iform);

  var form = document.createElement("form");
  document.body.appendChild(form);
  form.setAttribute("method", "GET");

  var input = document.createElement("input")
  input.id = "areaCode";
  input.name = "areaCode";
  input.setAttribute("type", "hidden");
  input.setAttribute("value", config.areaCode);
  form.appendChild(input);

  // http://localhost:9000/api/dataflow/olfonrealuser/queryRealSum?areaCode=1000&selectDateTime=2022-11-15+10:29:33
  // http://localhost:9000/api/exportExcel
  var url =  http.adornUrl('/dataflow/olfonrealuser/exportExcel');
  form.setAttribute("action", url);
  form.setAttribute("target","downloadIframe");

  //if(config.target == "_blank"){
    //form.setAttribute("target","_blank");
  //}

  console.log("config:"+ JSON.stringify(config))
  // console.log("url:"+ url)
  form.submit();
}
