import http from '@/utils/httpRequest'

//重大警示一级面板
export function oneMajorWaring(params){
    return http({
      url: http.adornUrl('/dataflow/olfmajorwarining/majorWarning'),
      method:'get',
      params:params
    })
  }

  //重大警示二级看板
export function getList(params){
  return http({
    url: http.adornUrl('/dataflow/olfmajorwarining/list'),
    method:'get',
    params:params
  })
}

  //重大警示详情列表
  export function getInfoList(params){
    return http({
      url: http.adornUrl('/dataflow/olfmajorwarining/infoList'),
      method:'get',
      params:params
    })
  }
  //获取重大警示统计时间
export function getMajorWarningDate(){
  return http({
    url: http.adornUrl('/dataflow/olfmajorwarining/getMajorWarningDate'),
    method:'post',
  })
}
export function getType(code) {
  return http({
    url: http.adornUrl(`/dataflow/olfmajorwarining/getType/${code}`),
    method: "get"
  })
}